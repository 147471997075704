import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ProductCard from '../ProductCard';
import ImageViewer from '../ImageViewer'; 


const Home = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const products = [
    { name: 'Cool Jacket', price: 99.99, image: '/images/jacket.jpg' },
    { name: 'Stylish Hat', price: 49.99, image: '/images/hat.jpg' },
    { name: 'Summer Shirts', price: 59.99, image: '/images/shirt.jpg' },
    { name: 'Leather Boots', price: 129.99, image: '/images/boots.jpg' },
    { name: 'Denim Jeans', price: 79.99, image: '/images/jeans.jpg' },
    { name: 'Vintage Sunglasses', price: 99.99, image: '/images/glasses.jpg'}
  ];

  const handleCardClick = (product) => {
    setSelectedProduct(product);
  };

  const closeImageViewer = () => {
    setSelectedProduct(null);
  };

  return (
    <div>
      <motion.div 
        className="hero bg-black text-white p-20 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-5xl font-bold">Welcome to Zypper</h1>
        <p className="mt-4 text-xl">The coolest fashion around</p>
      </motion.div>
      <div className="products-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-8">
        {products.map((product, index) => (
          <div key={index} onClick={() => handleCardClick(product)}>
            <ProductCard product={product} />
          </div>
        ))}
      </div>

      {selectedProduct && (
        <ImageViewer 
          product={selectedProduct} 
          onClose={closeImageViewer} 
        />
      )}
    </div>
  );
};

export default Home;