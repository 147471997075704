import React from 'react';
import { motion } from 'framer-motion';

const ImageViewer = ({ product, onClose }) => {
  return (
    <motion.div 
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      onClick={onClose}
    >
      <motion.div 
        className="relative bg-white p-4 rounded-lg max-w-lg w-full" 
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <button 
          className="absolute top-0 right-0 m-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        <img 
          src={product.image} 
          alt={product.name} 
          className="w-full h-auto rounded-lg"
        />
        <h2 className="text-2xl mt-4">{product.name}</h2>
        <p className="text-lg mt-2">${product.price.toFixed(2)}</p>
      </motion.div>
    </motion.div>
  );
};

export default ImageViewer;
