import React from 'react';
import { motion } from 'framer-motion';

const ProductCard = ({ product }) => {
  return (
    <motion.div 
      className="bg-white shadow-lg rounded-lg p-4 m-4"
      whileHover={{ scale: 1.05 }}
    >
      <img src={product.image} alt={product.name} className="w-full h-48 object-cover rounded-md" />
      <h2 className="text-xl font-bold mt-2">{product.name}</h2>
      <p className="text-gray-600 mt-1">${product.price}</p>
    </motion.div>
  );
};

export default ProductCard;
